interface ICredits {
  [section: string]: { [product: string]: string };
}

const CREDITS: ICredits = {
  Frameworks: {
    Gatsby: 'https://gatsbyjs.com',
    React: 'https://reactjs.org',
    SCSS: 'https://sass-lang.com',
  },
  Fonts: {
    Inter: 'https://rsms.me/inter/',
    'Cascadia Code': 'https://github.com/microsoft/cascadia-code',
  },
  Illustrations: {
    ManyPixels: 'https://www.manypixels.co/',
    Kapwing: 'https://www.kapwing.com/404-illustrations',
    'Isometric Love': 'https://www.isometriclove.com/',
  },
  'Helper Libraries': {
    'React Reveal': 'https://www.react-reveal.com/',
  },
};

export default CREDITS;
