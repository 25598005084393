import React from 'react';
import Layout from '../components/layout';
import Nav from '../components/Nav';
import Credits from '../components/Credits';
import page from '../HOCs/page';

export default page(
  () => (
    <Layout>
      <Nav currentLocation="Credits" returnLocation="home" />
      <Credits />
    </Layout>
  ),
  'credits'
);
