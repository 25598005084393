import React from 'react';
import withSEO from './withSEO';
import { PagesLike } from '../lib/seoData';

/**
 * A HOC for composing other HOCs that pages depend on.
 *
 * @param PageComponent Component that defines the page itself.
 * @param pageName Page title per se.
 */
export default function page(
  PageComponent: React.ComponentType,
  pageName: PagesLike
) {
  return withSEO(PageComponent, pageName);
}
