import React from 'react';
import SEO from '../components/seo';
import SEO_DATA, { PagesLike } from '../lib/seoData';

const withSEO = (
  WrappingComponent: React.ComponentType,
  page: PagesLike
): typeof WrappingComponent => {
  return class WrappedComponent extends React.Component<any, any> {
    render() {
      const seo = SEO_DATA[page];

      return (
        <>
          <SEO title={seo.title} description={seo.description} />
          <WrappingComponent {...this.props} />
        </>
      );
    }
  };
};

export default withSEO;
