export type PagesLike = 'home' | 'blog' | 'work' | '404' | 'credits';

export type ISEO_DATA = {
  [page in PagesLike]: { title: string; description: string };
};

const SEO_DATA: ISEO_DATA = Object.freeze({
  home: {
    title: 'Home',
    description:
      'Greetings, this is the portfolio website of D. Kasi Pavan Kumar. Consider this is a place on the internet where I express my thoughts about Programming, Space & Gaming.',
  },
  blog: {
    title: 'Blog',
    description:
      'Here you can find comprehensive articles, guides and news about web development, space & gaming.',
  },
  work: {
    title: 'Work',
    description:
      'Here you can find all the projects I have been working on. Most of them revolve around ReactJS, Express, PostgreSQL and MongoDB.',
  },
  '404': {
    title: '404',
    description:
      'This page is lost in space! If you ever found yourself here, you can either go back to the homepage or get some knowledge by reading few useful articles.',
  },
  credits: {
    title: 'Credits',
    description:
      'Credits to all the projects and resources which made this website possible.',
  },
});

export default SEO_DATA;
