import React, { useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CREDITS from './Credits.data';
import Typography, { TypographyProps } from '../core/Typography/Typography';
import classes from './Credits.module.scss';

const Fade = require('react-reveal/Fade');

const FADE_DISTANCE = '10px';

function useTimeOfDay() {
  const hour = new Date().getHours();

  const time: { [time: string]: boolean } = {
    'Morning!': hour > 6 && hour < 12,
    'Good noon!': hour >= 12 && hour < 17,
    'Good evening!': hour >= 17 && hour < 20,
    'Good night!': hour >= 20 && hour < 0,
  };

  return Object.keys(time).filter(function (e) {
    return !!time[e];
  });
}

export default function Credits() {
  const categories = useRef(Object.keys(CREDITS));
  const timeOfDay = useTimeOfDay();

  return (
    <div className={classes.container} data-message={timeOfDay}>
      <CreditsIllustration />
      <div className={classes.credits}>
        <Fade top cascade distance="10px">
          {categories.current.map((category) => (
            <div key={category} className={`${category}-s`}>
              <Category name={category} />
              {Object.keys(CREDITS[category]).map((t) => (
                <Product key={t} name={t} to={CREDITS[category][t]} link />
              ))}
            </div>
          ))}
        </Fade>
      </div>
    </div>
  );
}

interface ICategoryProps extends Partial<TypographyProps> {
  name: string;
}

interface IProductProps extends Partial<TypographyProps> {
  name: string;
}

function Category({ name, ...props }: ICategoryProps): JSX.Element {
  return (
    <Typography
      text={name}
      className={classes.title}
      fontVariant="primary"
      tag="h2"
      {...props}
    />
  );
}

function Product({ name, to }: IProductProps): JSX.Element {
  return (
    <Fade top distance={FADE_DISTANCE}>
      <Typography
        text={name}
        className={classes.subtitle}
        fontVariant="primary"
        tag="h3"
        to={to}
        link
      />
    </Fade>
  );
}

function CreditsIllustration() {
  const {
    file: { publicURL: creditsIllustration },
  } = useStaticQuery(graphql`
    {
      file(name: { eq: "team-work-isometric" }) {
        publicURL
      }
    }
  `);

  return (
    <div className={classes.illustration}>
      <Fade top cascade distance={FADE_DISTANCE}>
        <img
          data-testid="credits-illustration"
          src={creditsIllustration}
          alt="Credits"
        />
      </Fade>
    </div>
  );
}
